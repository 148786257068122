import config from './conf'
const _require = (url,verison, callBack) => {
  const styleNode = document.createElement('link')
  const scriptNode = document.createElement('script')
  // const splitArr = url.split('/')
  // const templateName = splitArr[splitArr.length - 1]
  styleNode.rel = 'stylesheet'
  styleNode.href = `${url}/template.css`
  // styleNode.onload = () => {}
  document.head.appendChild(styleNode)
  
  scriptNode.src = `${url}/${config['componentName']}`+'?v='+verison
  scriptNode.onload = () => {
    if (window['template']) {
      callBack(window['template'].default)
    } else {
      callBack(new Error('module not found'))
    }
    document.body.removeChild(scriptNode)
  }
  scriptNode.onerror = () => {
    callBack(new Error('module not found'))
  }
  document.body.appendChild(scriptNode)
}

export {
  _require
} 