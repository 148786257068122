<template>
  <div class="failed-container">
    <div class="img-container"><img :src="getErrorImg()" /></div>
    <!-- <div class="error-content">验证不通过</div> -->
    <div class="error-tips">{{ getErrorMsg() }}</div>
  </div>
</template>

<script>
export default {
  props: {
    errorCode: {
      type: Number,
      default: () => -1
    },
    errorMsg: {
      type: String,
      default: () => ''
    }
  },
  data() {
    return {
      errorCodeList: [14007, 14008, 14009, 14011, 14012],
      errorMap: {
        14007: {
          imgUrl: require('@/img/un-bind-tag.png'),
          errorMsg: '未绑定商品, 请绑定后重试'
        },
        14008: {
          imgUrl: require('@/img/system-error.png'),
          errorMsg: '系统错误, 已通知管理员, 我们会尽快解决'
        },
        14009: {
          imgUrl: require('@/img/verify-failed.png'),
          errorMsg: '请仔细核对, 谨防假冒'
        },
        14011: {
          imgUrl: require('@/img/invalid-QRCode.png'),
          errorMsg: '请扫描有效的二维码'
        },
        14012: {
          imgUrl: require('@/img/time-out.png'),
          errorMsg: '连接超时, 请重试'
        }
      }
    }
  },
  mounted() {
    document.title = '鉴真结果'
  },
  methods: {
    getErrorImg() {
      if (this.errorCodeList.includes(this.errorCode)) return this.errorMap[this.errorCode].imgUrl
      return require('@/img/normal-error.png')
    },
    getErrorMsg() {
      if (this.errorCodeList.includes(this.errorCode)) return this.errorMap[this.errorCode].errorMsg
      return this.errorMsg
    }
  }
}
</script>

<style lang="stylus" scoped>
.failed-container
  margin 0
  padding 0
  text-align center

.img-container
  margin-top 12vh
  img
    width 160px
.error-content
  color #FF4A3B
  margin 2rem auto 1.2rem auto
  font-size 1.5rem
  font-weight bold
.error-tips
  margin-top 1.5rem
  color #aaa
</style>
