import config from './conf'
// import { config } from 'vue/types/umd'
const axios = window['_axios']
// new instance.
const instance = axios.create({
  headers: {
    'content-type': 'application/json;charset=UTF-8'
  },
  timeout: 15000
})

// request => global filter
instance.interceptors.request.use(config => {
  config.headers.token = 'token'
  return config
}, error => {
  // something wrong
  return Promise.reject(error)
})

// response => global filter
instance.interceptors.response.use(response => {
  // do something
  return response
}, error => {
  // deal error
  return Promise.reject(error.response.data)
})

const GET = ({
  url,
  params
}) => {
  return new Promise((resolve, reject) => {
    axios.get({
      url,
      params
    }).then(res => {
      resolve(res)
    }).catch(error => {
      reject(error)
    })
  })
}

const headers = {}
if (process.env['VUE_APP_PLATFORM'] === 'h5') {
  headers['scan_type'] = config.scan_type
}
const POST = ({
  url,
  data
}) => {
  return new Promise((resolve, reject) => {
    axios.post(url, Object.assign({}, data, {source: config.source}, headers)).then(res => {
      res.data.code === 0 ? resolve(res.data) : reject(res.data)
    })
  })
}

export {
  GET,
  POST
}