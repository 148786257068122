<template>
  <div>
    <template v-if="loadDone && !loadFailed">
      <!-- <MainPage :qrCodeUrl="qrCodeUrl" :qrCode="qrCode" :env="env"/> -->
      <component :is="componentName" :qrCodeUrl="qrCodeUrl" :qrCode="qrCode" :env="env"/>
    </template>
    <template v-if="loadDone && loadFailed">
      <ErrorPage :errorCode="errorCode" :errorMsg="errorMsg" />
    </template>
    <!-- <template v-if="!verifyPass">
      <VerifyFailed />
    </template> -->
  </div>
</template>

<script>
import Vue from 'vue'
import { _require } from '@/utils/util'
import { fetchTemplatePath } from '@/utils/api'
import config from '@/utils/conf'
import ErrorPage from '@/views/error/ErrorPage'
import { dynamicData } from '@/utils/data'
// import VerifyFailed from '@/views/error/VerifyFailed'
let SipaBridge
if (
  process.env.NODE_ENV === 'development' &&
  ['app', 'jzapp'].includes(process.env.VUE_APP_PLATFORM)
) {
  console.log('---development---')
  const { initLocalBridge } = require('@/utils/local_native_bridge')
  initLocalBridge()
  SipaBridge = require('@/utils/bridgeApi.js').SipaBridge
} else if (
  process.env.NODE_ENV === 'production' &&
  ['app', 'jzapp'].includes(process.env.VUE_APP_PLATFORM)
) {
  console.log('---production---')
  SipaBridge = require('@/utils/bridgeApi.js').SipaBridge
}
// const { SipaBridge } = require('@/utils/bridgeApi.js')
export default {
  name: 'Admin',
  components: {
    ErrorPage
    // VerifyFailed
  },
  data() {
    return {
      loadDone: false,
      loadFailed: false,
      qrCode: {},
      // verifyPass: true, // 验证通过
      qrCodeUrl: config.host + location.pathname,
      errorMsg: '',
      errorCode: -1,
      env: process.env['VUE_APP_PLATFORM'],
      componentName: ''
    }
  },
  mounted() {
    if (process.env.VUE_APP_PLATFORM === 'h5') {
      this.init()
      return
    }
    if (dynamicData.init) {
      console.log('--------')
      console.log(dynamicData.init)
      this.init()
    } else {
      window.addEventListener('message', (e) => {
        if (e.data == 'SelfSipaJsBridgeReady') {
          this.init()
        }
      })
    }
  },
  methods: {
    init() {
      if (process.env.VUE_APP_PLATFORM === 'h5') {
        this.fetchData({
          source: config.source,
          qrcode_url: this.qrCodeUrl
        })
          // .then((res) => {
          //   if (!res) return
          //   const templatePath = res.data
          //   this.componentName = templatePath.split('/')
          //   _require(`${config['componentHost']}/${templatePath}`, (result) => {
          //     if (result instanceof Error) {
          //       this.loadDone = true
          //       this.loadFailed = true
          //       // 获取模板失败
          //       return
          //     }
          //     // Vue.component('MainPage', result)
          //     Vue.use(result)
          //     this.loadDone = true
          //     this.loadFailed = false
          //   })
          // })
          // .catch((error) => {
          //   this.loadDone = true
          //   this.loadFailed = true
          //   this.errorCode = error.code
          //   this.errorMsg = error.msg
          // })
        return
      }
      const _this = this
      if (window.performance.navigation.type === 2) {
        const paramValue = localStorage.getItem('baseCode')
        _this.fetchData(JSON.parse(paramValue))
      } else {
        SipaBridge.send2Native(
          SipaBridge.jzResult,
          { dest: 'params' },
          (result) => {
            if (result.code !== 0) {
              // deal error
              console.error(result.msg)
              return
            }
            localStorage.setItem('baseCode', JSON.stringify(result.data))
            const headers = SipaBridge.getHeaders()
            localStorage.setItem('headers', JSON.stringify(headers))
            _this.qrCode = result.data
            _this.fetchData(result.data)
          }
        )
      }
    },
    // 获取模板路径
    // pathname /qr/Ah_oLpp6pxpo8g
    fetchData(data) {
      return fetchTemplatePath(data)
        .then((res) => {
          if (!res) return
          const templatePath = res.data
          const splitArr = templatePath.split('/')
          this.componentName = splitArr[splitArr.length - 1].replace('-', '')
          _require(`${config['componentHost']}/${templatePath}`,res.msg, (result) => {
            if (result instanceof Error) {
              this.loadDone = true
              this.loadFailed = true
              // 获取模板失败
              return
            }
            // Vue.component('MainPage', result)
            Vue.use(result)
            this.loadDone = true
            this.loadFailed = false
          })
        })
        .catch((error) => {
          this.loadDone = true
          this.loadFailed = true
          this.errorCode = error.code
          this.errorMsg = error.msg
        })
    }
  }
}
</script>

<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
