/**
 * H5请求类
 * @author Lomon
 * @version 0.1.1 最后修改时间 2018年11月03日
 * @example
 * <script src="bridgeApi.js" id="sdkApiJs" uid="xxx"></script>
 */

var SipaBridge = {}
//SipaBridge.bridgeJsJs = document.getElementById('bridgeJs');
// 用户uid
//SipaBridge.uid = bridgeJsJs.attr('uid');

// sdk.js的版本号
SipaBridge.sdkVersion = '1.0.0'

// APP接口方法
SipaBridge.jump = 'jump' //跳转
SipaBridge.uploadimg = 'image_upload' //图片上传
SipaBridge.jzResult = 'jzResult' //鉴真界面获取APP参数,传参{"dest":"params"}或者{"dest":"headers"}
SipaBridge.scan2input = 'scan2input' // 转出物权

// 跳转到APP的地方
SipaBridge.home = 'home' //首页
SipaBridge.nfc = 'nfc' //鉴真
SipaBridge.login = 'login' //登录
SipaBridge.works = 'works' //作品列表
SipaBridge.collection = 'collection' //我的藏品
SipaBridge.oneQrcodeGenerate = 'one_qrcode_generate' //分享二维码
// 图片上传的参数和回调
/*
参数
"remote_rootdir" :"feedback", //存储桶一级目录 默认default
"photo_type" :"ORGIN", //ORGIN SMALL 默认default
"crop" :"1",//是否裁减 默认1
"max_size" :"0",//0 :不设置 单位字节  默认0
"max_width" :"0",//0 :不设置 单位字节  默认0
返回：
var width: CGFloat = 0
var height: CGFloat = 0
var size: Int = 0
var remoteFullPath: String = ""
var remotePath:String = ""
 */

SipaBridge.usable = false

// 初始化一个接口给APP使用
SipaBridge.init = function(bridge) {
  /*注册提供给native的接口*/
  bridge.registerHandler('test', function(message, responseCallback) {
    responseCallback({
      values: {
        status: '1',
        msg: 'ok',
        city: {
          cityName: message.cityName,
          cityProvince: message.cityProvince
        }
      }
    })
  })

  return this
}

//检测接口是否可用
SipaBridge.checkUsable = function(methodName, params, cb) {
  // var _this = this
  if (!window._SipaJSBridge) {
    console.log('no sipajsbridge')
    return
  }

  try {
    window._SipaJSBridge._doSendRequest(methodName, params, cb)
  } catch (e) {
    console.log('dosend exception')
  }
}

//获取头部信息
SipaBridge.getHeaders = function() {
  if (!window._SipaJSBridge || !window._SipaJSBridge.headers) {
    console.log('no sipajsbridge')
    return
  }
  var head = window._SipaJSBridge.headers
  head['meelinked-param'] = JSON.stringify(head['meelinked-param'])
  return head
}
// app通信
SipaBridge.registerHandler = function(methodName, params, cb) {
  if (!window._SipaJSBridge) {
    console.log('no sipajsbridge')
    return
  }
  try {
    window._SipaJSBridge.registerHandler(methodName, params, cb)
  } catch (e) {
    console.log('dosend exception')
  }
}
//调用app接口
SipaBridge.send2Native = function(action, params, cb) {
  this.checkUsable(action, params, cb)
}
;(function(root, factory) {
  root.SipaBridge = factory(root)
})(typeof window == 'undefined' ? this : window, function(win) {
  if (!win.document) {
    return {}
  }
  const { dynamicData } = require('./data')
  // var doc = win.document,
  //   title = doc.title,
  //   ua = navigator.userAgent.toLowerCase(),
  //   platform = navigator.platform.toLowerCase(),
  //   isMacorWin = !(!platform.match('mac') && !platform.match('win')),
  //   isandroid = -1 != ua.indexOf('android'),
  //   isphoneorpad = -1 != ua.indexOf('iphone') || -1 != ua.indexOf('ipad')
  dynamicData.init = false
  if (window._SipaJSBridge && SipaBridge) {
    console.log('native injection js success!')
    window._SipaJSBridge.debug = true
    SipaBridge.init(window._SipaJSBridge)
    dynamicData.init = true
  } else {
    console.log('native injection js wrong!')
    document.addEventListener(
      'SipaJsBridgeReady',
      function(event) {
        console.log('-----addEventListener-----callback')
        dynamicData.init = true
        event.bridge.debug = true
        window._SipaJSBridge = event.bridge
        SipaBridge.init(event.bridge)
        window.postMessage('SelfSipaJsBridgeReady', window.location.origin)
      },
      false
    )
  }
  if (
    window.webkit &&
    window.webkit.messageHandlers &&
    window.webkit.messageHandlers.iOS_Native_InjectJavascript
  ) {
    setTimeout(function() {
      window.webkit.messageHandlers.iOS_Native_InjectJavascript.postMessage(
        null
      )
    })
  }
})

export {
  SipaBridge
}
