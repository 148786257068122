import Vue from 'vue'
import './utils/mount'
import App from './App.vue'
import { Loading, Message } from 'element-ui'
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
import '@/assets/css/base.styl'
import config from '@/utils/conf'

Vue.config.productionTip = false
Vue.config.ignoredElements = [
  'a-scene',
  'a-entity',
  'a-sound',
  'a-box',
  'a-camera'
]
Viewer.setDefaults({
  navbar: false,
  title: false,
  button: false,
  toolbar: {}
})
Vue.use(Viewer)
Vue.use(Loading.directive)
Vue.prototype.$message = Message

Vue.mixin({
  data() {
    return {
      baseUrl: config['componentHost']
    }
  }
})

new Vue({
  render: h => h(App),
}).$mount('#app')
