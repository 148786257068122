const config = {}

const h5Config = {
  common: {
    source: 'h5',
    scan_type: 2
  },
  // 开发环境
  development: {
    // 模板域名
    componentHost: 'http://sipa.art.h5.3dku.me',
    // componentHost: 'https://jzinfo.meelinked.com', //正式
    host: 'http://sipa.art.h5.3dku.me',
    // host: 'https://jzinfo.meelinked.com/qr/Hre3efa2mX-SqQ', //正式
    componentName: 'template.umd.js'
  },
  // 测试环境
  test: {
    componentHost: window.location.origin,
    host: window.location.origin,
    componentName: 'template.umd.min.js'
  },
  // 生产环境
  production: {
    componentHost: window.location.origin,
    host: window.location.origin,
    componentName: 'template.umd.min.js'
  }
}

const appConfig = {
  common: {
    source: 'app'
  },
  // 开发环境
  development: {
    // 模板域名
    componentHost: 'http://sipa.art.temp.3dku.me',
    host: 'http://sipa.art.temp.3dku.me',
    componentName: 'template.umd.js'
  },
  // 测试环境
  test: {
    componentHost: window.location.origin,
    host: window.location.origin,
    componentName: 'template.umd.min.js'
  },
  // 生产环境
  production: {
    componentHost: window.location.origin,
    host: window.location.origin,
    componentName: 'template.umd.min.js'
  }
}

const jzappConfig = {
  common: {
    source: 'jzapp'
  },
  // 开发环境
  development: {
    // 模板域名
    componentHost: 'http://sipa.art.temp.3dku.me',
    host: 'http://sipa.art.temp.3dku.me',
    componentName: 'template.umd.js'
  },
  // 测试环境
  test: {
    componentHost: window.location.origin,
    host: window.location.origin,
    componentName: 'template.umd.min.js'
  },
  // 生产环境
  production: {
    componentHost: window.location.origin,
    host: window.location.origin,
    componentName: 'template.umd.min.js'
  }
}

const envMap = {
  h5: h5Config,
  app: appConfig,
  jzapp: jzappConfig
}
const envConfig = envMap[process.env['VUE_APP_PLATFORM']]
if (envConfig) {
  Object.assign(config, envConfig.common, envConfig[process.env.VUE_APP_ENV])
  console.log(config)
}

module.exports = config
