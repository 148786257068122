import { POST } from './http'

export const fetchBaseInfo = data => {
  return POST({url: '/api/identify/baseinfo', data})
}

export const fetchScanLog = data => {
  return POST({url: '/api/identify/scanLog', data})
}

export const fetchChainInfo = data => {
  return POST({url: '/api/chain/infoByTxhash', data})
}

export const fetchLogByTxhash = data => {
  return POST({url: '/api/chain/logByTxhash', data})
}

export const fetchTemplatePath = data => {
  return POST({url: '/api/identify/getTemplatePath', data})
}